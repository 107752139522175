import React, { Component, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Error caught by Error Boundary: ", error, errorInfo);

        // Automatically reload after a short delay
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h1>Oops! Something went wrong.</h1>
                    <p>The page will reload automatically in a second...</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;