import React, { useEffect, useRef, FC } from 'react';
import {MindARViewerProps} from "types";

export const MindARDynamicViewer: FC<MindARViewerProps> = ({
                                                        assets,
                                                        mindFileSrc,
                                                        onMarkerFound,
                                                        onMarkerLost,
                                                        onARReady,
                                                    }) => {
    const sceneRef = useRef<any>(null);

    useEffect(() => {
        const sceneEl = sceneRef.current;

        if (sceneEl) {
            sceneEl.addEventListener('renderstart', () => {
                const arSystem = sceneEl.systems['mindar-image-system'];
                if (arSystem) {
                    arSystem.start();
                    onARReady();
                } else {
                    console.error('MindAR system is not available');
                }
            });

            const eventHandlers: {
                [key: number]: { found: () => void; lost: () => void };
            } = {};

            assets.forEach(({ targetIndex }) => {
                const targetEntity = sceneEl.querySelector(`#target-${targetIndex}`);

                if (targetEntity) {
                    const handleFound = () => {
                        // Hide all other assets
                        assets.forEach(({ targetIndex: otherIndex }) => {
                            const otherEntity = sceneEl.querySelector(`#target-${otherIndex}`);
                            if (otherEntity) {
                                otherEntity.setAttribute('visible', otherIndex === targetIndex ? 'true' : 'false');
                            }
                        });
                        onMarkerFound(targetIndex);
                    };

                    const handleLost = () => {
                        onMarkerLost();
                        // Keep the asset visible due to missTolerance
                    };

                    targetEntity.addEventListener('targetFound', handleFound);
                    targetEntity.addEventListener('targetLost', handleLost);

                    // Store handlers for cleanup
                    eventHandlers[targetIndex] = { found: handleFound, lost: handleLost };
                }
            });

            // Cleanup function to remove event listeners
            return () => {
                assets.forEach(({ targetIndex }) => {
                    const targetEntity = sceneEl.querySelector(`#target-${targetIndex}`);
                    if (targetEntity) {
                        targetEntity.removeEventListener(
                            'targetFound',
                            eventHandlers[targetIndex].found
                        );
                        targetEntity.removeEventListener(
                            'targetLost',
                            eventHandlers[targetIndex].lost
                        );
                    }
                });
            };
        }
    }, [assets, onMarkerFound, onMarkerLost, onARReady]);

    return (
        <a-scene
            ref={sceneRef}
            mindar-image={`imageTargetSrc: ${mindFileSrc}; autoStart: false; uiLoading: no; uiError: no; uiScanning: no; missTolerance: 100; filterMinCF: 0.01; filterBeta: 10; maxTrack: 1; skipTargetProcessing: 2;`}
            color-space="sRGB"
            renderer="colorManagement: true, physicallyCorrectLights, shadowMapEnabled: false;"
            vr-mode-ui="enabled: false"
            device-orientation-permission-ui="enabled: true"
            loading-screen="enabled: false"
            antialias="false"
        >
            <a-assets>
                {assets.map(({ targetIndex, glbModelSrc }) => (
                    <a-asset-item key={`asset-${targetIndex}`} id={`model-${targetIndex}`} src={glbModelSrc}></a-asset-item>
                ))}
            </a-assets>

            <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>

            {assets.map(({ targetIndex }) => (
                <a-entity
                    key={`target-${targetIndex}`}
                    id={`target-${targetIndex}`}
                    mindar-image-target={`targetIndex: ${targetIndex}`}
                    visible="false" // Initially hide all assets
                >
                    <a-gltf-model
                        rotation="0 0 0"
                        position="0 0 0.1"
                        scale="0.1 0.1 0.1"
                        src={`#model-${targetIndex}`}
                        lerp="duration: 1000"
                    ></a-gltf-model>
                </a-entity>
            ))}
        </a-scene>
    );
};