import React, { FC, useEffect, useRef } from 'react';
import {assets} from "../assets";

interface MindARMarkerDetectorProps {
  mindFileSrc: string;
  onMarkerFound: (targetIndex: number) => void;
  onARReady: () => void;
}

const MindARMarkerDetector: FC<MindARMarkerDetectorProps> = ({
                                                               mindFileSrc,
                                                               onMarkerFound,
                                                               onARReady,
                                                             }) => {
  const sceneRef = useRef<any>(null);

  useEffect(() => {
    const sceneEl = sceneRef.current;

    if (!sceneEl) {
      console.error('Scene ref is null');
      return;
    }

    const handleSceneLoaded = () => {
      const arSystem = sceneEl.systems['mindar-image-system'];
      if (arSystem) {
        const startResult = arSystem.start();
        if (startResult && typeof startResult.then === 'function') {
          startResult.then(() => {
            onARReady();
          }).catch((error: any) => {
            console.error("Failed to start AR system:", error);
          });
        } else {
          onARReady();
        }
      }
    };

    const handleMarkerFound = (event: any) => {
      const targetIndex = event.target.getAttribute('mindar-image-target').targetIndex;
      console.log(`Marker found with index: ${targetIndex}`);
      onMarkerFound(parseInt(targetIndex, 10));
    };

    sceneEl.addEventListener('loaded', handleSceneLoaded);
    sceneEl.addEventListener('targetFound', handleMarkerFound);

    return () => {
      sceneEl.removeEventListener('loaded', handleSceneLoaded);
      sceneEl.removeEventListener('targetFound', handleMarkerFound);
    };
  }, [mindFileSrc, onMarkerFound, onARReady]);

  return (
      <a-scene
          ref={sceneRef}
          mindar-image={`imageTargetSrc: ${mindFileSrc}; autoStart: true; uiScanning: no; uiLoading: no;`}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
      >
        <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>

        {assets.map((asset, index) => (
            <a-entity
                key={`target-${index}`}
                mindar-image-target={`targetIndex: ${index}`}
                visible="false"
            >
              <a-plane
                  color="blue"
                  opacity="0.5"
                  position="0 0 0"
                  height="0.552"
                  width="1"
                  rotation="0 0 0"
              ></a-plane>
            </a-entity>
        ))}
      </a-scene>
  );
};

export default MindARMarkerDetector;