import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {TapIcon} from "./TapIcon";

const CornerLine: React.FC<{ position: string }> = ({ position }) => {
    const baseStyle: React.CSSProperties = {
        position: "absolute",
        width: "50px",
        height: "50px",
        borderTop: "9px solid white",
        borderLeft: "9px solid white",
    };

    const positionStyles: Record<string, React.CSSProperties> = {
        topLeft: { top: 0, left: 0 },
        topRight: { top: 0, right: 0, borderLeft: "none", borderRight: "9px solid white" },
        bottomLeft: { bottom: 0, left: 0, borderTop: "none", borderBottom: "9px solid white" },
        bottomRight: {
            bottom: 0,
            right: 0,
            borderTop: "none",
            borderLeft: "none",
            borderRight: "9px solid white",
            borderBottom: "9px solid white",
        },
    };

    return <div style={{ ...baseStyle, ...positionStyles[position] }} />;
};

interface ArViewUIProps {
    isVisible: boolean;
}

export const ArViewUI: React.FC<ArViewUIProps> = ({ isVisible }) => {
    const [shouldRender, setShouldRender] = useState(isVisible);

    useEffect(() => {
        if (isVisible) {
            setShouldRender(true); // Show immediately when visible
        } else {
            const timeout = setTimeout(() => setShouldRender(false), 500); // Adjust duration to match your animation
            return () => clearTimeout(timeout);
        }
    }, [isVisible]);

    if (!shouldRender) return null;

    return (
        <Box
            className={isVisible ? "ar-view-ui-enter" : "ar-view-ui-exit"}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
            }}
        >

            {/* Target Scanner */}
            <Box
                style={{
                    position: "absolute",
                    top: "20svh",
                    width: "40svh",
                    height: "40svh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    zIndex: 9, // Below the button but above the other UI elements
                }}
            >
                {/* Scanner corner lines */}
                <CornerLine position="topLeft" />
                <CornerLine position="topRight" />
                <CornerLine position="bottomLeft" />
                <CornerLine position="bottomRight" />

                {/* Scanning Line */}
                <div
                    style={{
                        position: "absolute",
                        width: "96.5%",
                        height: "9px",
                        backgroundColor: "white",
                        animation: "scan 2s ease-in-out infinite",
                    }}
                />
            </Box>

            {/* "Point the camera at the target" instruction */}
            <Paper
                elevation={3}
                style={{
                    position: "absolute",
                    top: "74svh",
                    padding: "1rem",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "38svh",
                    height: "9svh",
                    zIndex: 10,
                }}
            >
                <TapIcon />
                <Typography
                    variant="h6"
                    style={{
                        textAlign: "center",
                        color: "#333",
                        fontWeight: 100,
                        fontSize: "calc(2vh + 0.7vw)",
                        lineHeight: "calc(2vh + 1vw)",
                    }}
                >
                    Point the camera at the <strong>target</strong>
                </Typography>
            </Paper>
        </Box>
    );
};