import React, { useState, useEffect } from "react";
import "./App.css";
import { MindARDynamicViewer } from "./RenderingComponents/MindARDynamicViewer";
import { Box, Button } from "@mui/material";
import { ArViewUI } from "./UI/ArViewUI";
import { assets } from "./assets";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLocation } from "react-router-dom";
import MindARMarkerDetector from "./RenderingComponents/MindARMarkerDetector";
import { RenderStrategy } from "./types/enums";
import StaticAssetViewer from "./RenderingComponents/StaticAssetViewer";
import ErrorBoundary from "./ErrorBoundary";

const App: React.FC = () => {
    const [isARReady, setIsARReady] = useState(false);
    const [isAssetBeingRendered, setIsAssetBeingRendered] = useState(false);
    const [hideAssetTimer, setHideAssetTimer] = useState<number | null>(null);
    const [mindARViewerKey, setMindARViewerKey] = useState(0);
    const [currentTargetIndex, setCurrentTargetIndex] = useState<number | null>(null);
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const renderingStrategy = params.get('rendering') || RenderStrategy.STATIC;

    const handleARReady = () => {
        setIsARReady(true);
    };

    const handleMarkerFound = (targetIndex?: number) => {
        console.log(`Marker ${targetIndex} found`);
        if (hideAssetTimer !== null) {
            clearTimeout(hideAssetTimer);
            setHideAssetTimer(null);
        }

        // Clean up existing asset before rendering new one
        setCurrentTargetIndex(null);
        setTimeout(() => {
            setCurrentTargetIndex(targetIndex ?? null);
            setIsAssetBeingRendered(true);
        }, 100);  // Adding a small delay to ensure proper cleanup
    };

    const handleMarkerLost = () => {
        const timer = window.setTimeout(() => {
            setIsAssetBeingRendered(false);
            setHideAssetTimer(null);
        }, 200);
        setHideAssetTimer(timer);
    };

    useEffect(() => {
        return () => {
            if (hideAssetTimer !== null) {
                clearTimeout(hideAssetTimer);
            }
        };
    }, [hideAssetTimer]);

    const handleResetTargetSearch = async () => {
        console.log("Resetting AR system and starting new target search...");
        setMindARViewerKey((prevKey) => prevKey + 1);
        setIsAssetBeingRendered(false);
        setCurrentTargetIndex(null);
    };

    return (
        <ErrorBoundary>

        <Box
            style={{
                position: "relative",
                width: "100vw",
                height: "100svh",
                overflow: "hidden",
            }}
        >
            {isAssetBeingRendered && (
                <Box
                    style={{
                        position: "absolute",
                        top: "90svh",
                        right: "5vw",
                        zIndex: 11,
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={handleResetTargetSearch}
                        size="large"
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "calc(8px + 1.7vmin)",
                            padding: "10px 20px",
                            borderRadius: "10px",
                            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                            border: "2px solid #A100FF",
                            zIndex: 1000,
                            textTransform: "none",
                        }}
                    >
                        <RefreshIcon
                            style={{
                                marginRight: '8px',
                                fontSize: "calc(18px + 1.7vmin)",
                            }}
                        />
                        Scan another one
                    </Button>
                </Box>
            )}

            {!isAssetBeingRendered && <ArViewUI isVisible={!isAssetBeingRendered} />}

            {renderingStrategy === RenderStrategy.DYNAMIC && (
                <MindARDynamicViewer
                    key={mindARViewerKey}
                    onMarkerFound={handleMarkerFound}
                    onMarkerLost={handleMarkerLost}
                    mindFileSrc="/targets.mind"
                    assets={assets}
                    onARReady={handleARReady}
                />
            )}

            {renderingStrategy === RenderStrategy.STATIC && (
                <>
                    {/* Keep AR view always active */}
                    <MindARMarkerDetector
                        onMarkerFound={handleMarkerFound}
                        mindFileSrc="/targets.mind"
                        onARReady={handleARReady}
                    />

                    {/* Render asset viewer on top of AR view */}
                    {isAssetBeingRendered && currentTargetIndex !== null && (
                        <StaticAssetViewer
                            glbModelSrc={
                                assets.find((asset) => asset.targetIndex === currentTargetIndex)?.glbModelSrc || ""
                            }
                        />
                    )}
                </>
            )}

            {(!isARReady) && (
                <div
                    className="accenture-placeholder"
                    style={{ position: "absolute", top: '-9%' }}
                >
                    <h1>Accenture</h1>
                </div>
            )}
        </Box>
        </ErrorBoundary>
    );
};

export default App;