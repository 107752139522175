import React from "react";

export const TapIcon = () => {
    return <div
        style={{
            position: 'absolute',
            display: 'flex',
            top: '-19%',
            width: '6vh',
            height: '6vh',
        }}
    >
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_0_1)">
                <rect x="4" y="2" width="80" height="80" rx="40" fill="#A100FF"/>
            </g>
            <g clipPath="url(#clip0_0_1)">
                <path
                    d="M59.8594 44.9004L48.9983 41.7972V31.9998C48.9983 28.6871 46.3109 25.9998 42.9983 25.9998C39.6875 25.9998 37 28.6871 37 31.9998V49.275L35.2379 47.5158C32.8984 45.1722 29.0975 45.1758 26.7532 47.5158C24.4138 49.8594 24.4138 53.6573 26.7532 55.9998L36.754 66H59.7052L62.92 49.9218C63.3514 47.7654 61.9744 45.5064 59.8594 44.9004ZM56.4256 61.9998H38.4136L29.5817 53.1714C28.8041 52.3944 28.8041 51.1254 29.5817 50.3435C30.3629 49.5618 31.6325 49.5641 32.4136 50.3435L41.0002 58.9295V31.9998C41.0002 30.894 41.8924 30 42.9983 30C44.1017 30 44.9981 30.8946 44.9981 31.9998V44.8158L59.059 48.8316L56.4256 61.9998Z"
                    fill="white"/>
                <path
                    d="M43 18C35.2696 18 29.0002 24.2677 29.0002 32.0016C29.0002 33.9 29.3823 35.7071 30.0664 37.3571L33.7654 35.8241C33.2752 34.6462 32.9998 33.357 32.9998 32.0014C32.9998 26.4761 37.4764 21.9995 43 21.9995C48.5236 21.9995 53.0002 26.4761 53.0002 32.0014C53.0002 33.3574 52.7248 34.6462 52.2364 35.8241L55.9335 37.3571C56.6187 35.7066 56.9997 33.9 56.9997 32.0016C56.9998 24.2677 50.7328 18 43 18Z"
                    fill="white"/>
                <path
                    d="M26.371 25.1112L22.6756 23.5806C21.5998 26.1762 20.9998 29.016 20.9998 32.0004C20.9998 34.9823 21.5998 37.8221 22.6756 40.416L26.371 38.8866C25.492 36.7631 25 34.4387 25 31.9998C25 29.5584 25.492 27.2346 26.371 25.1112Z"
                    fill="white"/>
                <path
                    d="M63.3244 23.58L59.629 25.1094C60.5098 27.2323 61 29.5584 61 31.9998C61 34.4388 60.5098 36.765 59.629 38.8866L63.3244 40.4177C64.4002 37.8239 65.0002 34.9823 65.0002 31.9997C65.0002 29.0154 64.4002 26.1738 63.3244 23.58Z"
                    fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_0_1" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                </filter>
                <clipPath id="clip0_0_1">
                    <rect width="48" height="48" fill="white" transform="translate(19 18)"/>
                </clipPath>
            </defs>
        </svg>
    </div>;
}